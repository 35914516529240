/* Border Box */
* {
    box-sizing: border-box;
}
/* Fonts */
body {
    font-family: Roboto;
}
#timeline {
    color: #000000;
    padding: 50px 0;
    background: #f7f7f7;
    border-top: 1px solid rgba(191, 191, 191, 0.4);
    border-bottom: 1px solid rgba(191, 191, 191, 0.4);
}
#timeline h1 {
    text-align: center;
    font-size: 3rem;
    font-weight: 200;
    margin-bottom: 20px;
}
#timeline p.leader {
    text-align: center;
    max-width: 90%;
    margin: auto;
    margin-bottom: 45px;
}
#timeline .demo-card-wrapper {
    position: relative;
    margin: auto;
}
@media (min-width: 1000px) {
    #timeline .demo-card-wrapper {
        display: flex;
        flex-flow: column wrap;
        width: 1170px;
        height: 2140px;
        margin: 0 auto;
   }
}

@media (min-width: 1000px) {
    #timeline .demo-card-wrapper::after {
        border-left: 1px solid #bdbdbd;
   }
}
#timeline .demo-card {
    position: relative;
    display: block;
    margin: 20px;
    max-width: 94%;
    z-index: 2;
    transition: transform .2s;
}
.demo-card-clickable:hover {
    z-index: 3;
    cursor: pointer;
    transform: scale(1.1);
}
@media (min-width: 480px) {
    #timeline .demo-card {
        max-width: 60%;
        box-shadow: 0px 1px 22px 4px rgba(0, 0, 0, 0.07);
   }
}
@media (min-width: 720px) {
    #timeline .demo-card {
        max-width: 40%;
   }
}
@media (min-width: 1000px) {
    #timeline .demo-card {
        max-width: 450px;
        height: 400px;
        /* margin: 90px;
        margin-top: 45px;
        margin-bottom: 45px; */
   }

   .grid {
        margin: 0 350px;
   }
}
#timeline .demo-card .head {
    position: relative;
    display: flex;
    align-items: center;
    color: #000000;
    font-weight: 400;
}
#timeline .demo-card .head .number-box {
    display: inline;
    float: left;
    margin: 15px;
    padding: 10px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    background: rgba(0, 0, 0, 0.17);
}
#timeline .demo-card .head h2 {
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: inherit;
    letter-spacing: 2px;
    margin: 0;
    padding-bottom: 6px;
    line-height: 1rem;
}
@media (min-width: 480px) {
    #timeline .demo-card .head h2 {
        font-size: 120%;
        line-height: 1.2rem;
        margin-top: 10px
   }
}
#timeline .demo-card .head h2 span {
    display: block;
    font-size: 0.6rem;
    margin: 0;
}
@media (min-width: 480px) {
    #timeline .demo-card .head h2 span {
        font-size: 0.8rem;
   }
}
#timeline .demo-card .body {
    background: #fff;
    border: 1px solid rgba(191, 191, 191, 0.4);
    border-top: 0;
    padding: 15px;
}
@media (min-width: 1000px) {
    #timeline .demo-card .body {
        height: 315px;
   }
}
#timeline .demo-card .body p {
    font-size: 14px;
    line-height: 18px;
    margin-top: 15px;
}
#timeline .demo-card .body img {
    display: block;
    /* width: 100%;/ */
    object-fit: cover;
    height: 75%;
    
}

.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}